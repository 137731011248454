@import "~react-vis/dist/style";
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Text:wght@300;400;600;700&display=swap');

body {
  background-color: #eee;
  font-family: 'Red Hat Text', sans-serif;
  color: #282828;
}

input:focus {
  outline: none;
}

.react-grid-item>.react-resizable-handle::after {
  border-color: #ccc;
}

@layer components {
  .input {
    @apply appearance-none rounded w-full py-3 px-4 mb-2 bg-gray-100 text-gray-700 outline-none relative leading-tight;
  }

  .label {
    @apply font-semibold text-gray-600 mb-2 text-sm;
  }

  .link {
    @apply inline align-baseline text-primary hover:text-[#372472] active:text-[#372472];
  }

  .btn {
    @apply font-semibold uppercase py-3 px-7 rounded-xl text-center;
  }

  .btn:disabled {
    @apply opacity-70 cursor-not-allowed;
  }

  .btn-primary {
    @apply bg-primary hover:bg-[#372472] transition text-secondary;
  }

  .btn-secondary {
    @apply bg-secondary text-primary hover:bg-[#77B21A] transition;
  }

  .btn-danger {
    @apply bg-red-600 hover:bg-red-700 transition text-white;
  }

}
.select-wrapper {
  position: relative;
}
.select-wrapper::after{
  content: "▼";
  /* position: relative; */
  color: #472f92;
  right: 1rem;
  top: .75rem;
  font-size: .75rem;
  pointer-events: none;
  position: absolute;
} 
.height-full {
  height: 100dvh;
}

.drag-handle {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.drag-handle:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.mini-scrollbar::-webkit-scrollbar {
  width: .3rem;
  border-radius: .25rem;
}
.mini-scrollbar-x::-webkit-scrollbar {
  height: .3rem;
  border-radius: .25rem;
}

.mini-scrollbar::-webkit-scrollbar-track, .mini-scrollbar-x::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  margin: 5px 0;
}

.mini-scrollbar::-webkit-scrollbar-thumb, .mini-scrollbar-x::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: .25rem;
}

.mini-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #ccc;
}

.menu-item-desktop>.menu-item-desktop-options {
  display: none;
}

.menu-item-desktop:hover>.menu-item-desktop-options {
  display: block;
}
.menu-item-desktop:last-child>.menu-item-desktop-options {
  right: .5rem;
}

.show-on-hover-parent .show-on-hover {
  display: none;
}

.show-on-hover-parent:hover .show-on-hover {
  display: block;
}

.blink {
  animation: blink .5s ease-in;
}

@keyframes blink {
  0% { opacity: 0 }
  100% { opacity: 100%; }
}

.checkbox-container input:checked ~ .checkbox-content {
  background-color: #472f92;
}
.checkbox-container .checkbox-content::after {
  top: 2px;
  left: 7px;
  width: 7px;
  height: 15px;
  border: solid #9ade31;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.checkbox-container .checkbox-content.color-primary::after {
  top: 2px;
  left: 7px;
  width: 7px;
  height: 15px;
  border: solid #472f92;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.checkbox-container .checkbox-content.color-disabled::after {
  top: 2px;
  left: 7px;
  width: 7px;
  height: 15px;
  border: solid #AAAAAA;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.checkbox-content::after {
  content: '';
  position: absolute;
  display: none;
}
.checkbox-container input:checked ~ .checkbox-content::after {
  display: block;
}

.modal-content-mobile {
  top: 50% !important;
  transform: translateY(-50%);
}

.modal-title {
  font-size: 20px;
  color: #282828;
  line-height: 26px;
}

.modal-text {
  font-size: 16px;
  color: #4F4F4F;
  line-height: 26px;
  text-align: left;
}

.modal-confirm-button {
  background-color: #9ADE31;
  color: #472F92;
  padding: 15px 30px;
  border-radius: 12px;
}

.modal-cancel-button {
  background-color: #9ADE31;
  color: #472F92;
  padding: 15px 30px;
  border-radius: 12px;
}

summary {
  list-style: none;
}

summary::after {
  content: url('/src/assets/icons/expand_more.svg');
  color: #472f92;
  position: absolute;
}

details[open] summary::after {
  content: url('/src/assets/icons/expand_less.svg');
}

summary::-webkit-details-marker, summary::marker {
  content: '';
  display: none;
}

table.fixed-table {
  position: relative;
  border-collapse: separate;
  border-spacing: 0;
  border: none

}

table.fixed-table > thead > tr {
  background: white;
  position: sticky;
  top: 0;
  z-index: 20;
}

table.fixed-table > thead > tr > th {
  border-width: 1px;
  border-left-width: 0;
}

table.fixed-table > thead > tr > th:first-child {
  background: white;
  position: sticky;
  left: 0;
  z-index: 30;
  border-left-width: 1px;
}

table.fixed-table > tbody > tr > td:first-child {
  background: white;
  position: sticky;
  left: 0;
  z-index: 10;
  border-left-width: 1px;
}


table.fixed-table > tbody > tr:last-child > td {
  border-bottom-width: 1px;
}

table.fixed-table > tbody > tr > td:last-child {
  border-right-width: 1px;
}

table.fixed-table > tbody > tr:hover > td {
  @apply bg-gray-100;
}
